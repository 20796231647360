import { WithWidth } from '@material-ui/core/withWidth';
import { useTranslation } from '../../hooks/useTranslation';
import React, { useState } from 'react';
import useForgotPasswordForm from '../../hooks/useForgotPasswordForm';
import ForgotPasswordForm from '../../components/forgot-password-form/ForgotPasswordForm';
import {
  Card,
  Container,
  Grid,
  Hidden,
  makeStyles,
  withWidth,
} from '@material-ui/core';
import { usePlannedDownTimeRedirect } from '../../hooks/usePlannedDownTimeRedirect';
import Drawer from '../../components/expansion-panel';
import Typography from '@material-ui/core/Typography';
import useQuickLogin from '../../hooks/useQuickLogin';
import QuickLoginForm from '../../components/quick-login-form/QuickLoginForm';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 12, 16, 12),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2, 10, 2),
    },
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  card: {
    height: '100%',
    minWidth: 275,
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 2em',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
}));
export enum InputType {
  QUICK_LOGIN = 'QUICK_LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
}
export interface ForgotPasswordPageProps extends WithWidth {
  path: string;
}
const ForgotPasswordPage = (props: ForgotPasswordPageProps) => {
  usePlannedDownTimeRedirect();
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = ['xs', 'sm'].includes(props.width);
  const form = useForgotPasswordForm();
  const [expandedPanel, setExpandedPanel] = useState<InputType | ''>('');
  const {
    quickform,
    quickLoginhandleSubmit,
    successMessage,
    handleBack,
  } = useQuickLogin({
    newEmail: '',
    previousEmail: '',
  });
  const handlePanelChange = (panel: InputType) => {
    setExpandedPanel(prevPanel => (prevPanel === panel ? '' : panel));
  };
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Hidden smUp>
        <Grid style={{ marginBottom: '16px' }}>
          <Drawer
            title={t('QUICK_LOGIN')}
            style={{
              padding: '0px 16px 16px',
            }}
            defaultExpand={expandedPanel === InputType.QUICK_LOGIN}
            onChange={handlePanelChange}
            name={InputType.QUICK_LOGIN}
            labelStyle={{ fontWeight: 'bold' }}
          >
            <QuickLoginForm
              form={quickform}
              handleBack={handleBack}
              handleSubmit={quickLoginhandleSubmit}
              successMessage={successMessage}
              isForgotPassword={false}
            />
          </Drawer>
        </Grid>
        <Grid>
          <Drawer
            title={t('FORGOT_PASSWORD')}
            style={{
              padding: '0px 16px 16px',
            }}
            defaultExpand={expandedPanel === InputType.FORGOT_PASSWORD}
            onChange={handlePanelChange}
            name={InputType.FORGOT_PASSWORD}
            labelStyle={{ fontWeight: 'bold' }}
          >
            <ForgotPasswordForm form={form} isMobile={isMobile} {...props} />
          </Drawer>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid
          container
          direction={'row'}
          spacing={2}
          className={classes.gridContainer}
        >
          <Grid item xs={6}>
            <Typography variant="h1" className={classes.title}>
              {t('QUICK_LOGIN')}
            </Typography>
            <Card className={classes.card}>
              <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                {t('QUICK_LOGIN_TEXT')}
              </Typography>
              <QuickLoginForm
                form={quickform}
                handleBack={handleBack}
                handleSubmit={quickLoginhandleSubmit}
                successMessage={successMessage}
                isForgotPassword={true}
              />
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h1" className={classes.title}>
              {t('FORGOT_PASSWORD')}
            </Typography>
            <Card className={classes.card}>
              <ForgotPasswordForm form={form} isMobile={isMobile} {...props} />
            </Card>
          </Grid>
        </Grid>
      </Hidden>
    </Container>
  );
};

export default withWidth()(ForgotPasswordPage);
