import React, { FunctionComponent, useContext, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import Button from '../buttons/PGE-Button';
import PinTextField from '../pin-text-field';
import AlphaNumericTextField from '../alpha-numeric-text-field';
import { Model } from './ForgotPasswordForm.rules';
import ForgotPasswordFormProps from './ForgotPasswordForm.types';
import DatePicker from '../date-picker';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import EmailOrPhoneTextField from '../email-or-phone-text-field';
import Dropdown from '../dropdown';
import { useLocation } from '@reach/router';
import { Grid } from '@material-ui/core';
import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) => ({
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 0,
  },

  submit: {
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export const defaultFormValues: Model = {
  ssn: '',
  driversLicense: '',
  pin: '',
  dob: '',
  ein: '',
  itin: '',
  identificationType: 'ssn',
  userIdType: 'email',
  emailPhone: '',
};

const ForgotPasswordForm: FunctionComponent<ForgotPasswordFormProps> = (
  props: ForgotPasswordFormProps,
) => {
  const classes = useStyles(props);
  const { t, richT } = useTranslation();
  const { form } = props;
  const location = useLocation();
  const locationState = location.state as {
    redirectUrl: string;
    showNotification: boolean;
  };
  const notificationContext = useContext(NotificationsContext);
  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const inputElement = e.currentTarget.elements[0] as HTMLInputElement;
    if (inputElement?.type) {
      notificationContext.setState({
        isOpen: false,
        message: undefined,
        severity: undefined,
      });
    }
  };

  useEffect(() => {
    if (locationState?.showNotification) {
      notificationContext.setState({
        isOpen: true,
        message: t('FAILED_ATTEMPT_NOTIFICATION_TEXT'),
        severity: 'error',
      });
    }
  }, [locationState?.showNotification]);

  return (
    <>
      <div style={{ justifyContent: 'flex-start' }}>
        <Typography variant="body2" style={{ fontWeight: 'bold' }}>
          {t('FORGOT_PASSWORD_RESET_TEXT')}
        </Typography>
        <Typography component={'div'} variant={'body2'}>
          {richT('FORGOT_PASSWORD_STEP1_TEXT')}
        </Typography>
        <form noValidate onSubmit={form.handleSubmit} onChange={handleChange}>
          <EmailOrPhoneTextField
            autoFocus={true}
            data-testid="email-phone-input"
            name="emailPhone"
            variant="outlined"
            style={{ width: '100%' }}
            {...form.props('emailPhone')}
          />
          <Typography component={'div'} variant={'body2'}>
            {richT('FORGOT_PASSWORD_STEP2_TEXT')}
          </Typography>
          <Dropdown
            label={''}
            value={form.values.identificationType}
            selectionList={[
              { value: 'ssn', label: t('SOCIAL_SECURITY_NUMBER') },
              { value: 'dob', label: t('DATE_OF_BIRTH') },
              { value: 'pin', label: t('PINCODE') },
              { value: 'driversLicense', label: t('DRIVERS_LICENSE') },
              { value: 'itin', label: t('ITIN') },
              { value: 'ein', label: t('EIN_NUMBER') },
            ]}
            onChange={e => {
              void form.setValue('identificationType', e.target.value);
            }}
          />
          {form.values.identificationType === 'ssn' && (
            <PinTextField
              name="ssn"
              label={t('FORGOT_PASSWORD_LAST_FOUR_SSN')}
              autoComplete="ssn"
              inputProps={{ minLength: 4, maxLength: 4 }}
              {...form.props('ssn')}
            />
          )}
          {form.values.identificationType === 'driversLicense' && (
            <AlphaNumericTextField
              name="driversLicense"
              label={t('FORGOT_PASSWORD_STATE_ID')}
              autoComplete={t('FORGOT_PASSWORD_STATE_ID')}
              type="text"
              inputProps={{ minLength: 4, maxLength: 4 }}
              {...form.props('driversLicense')}
            />
          )}
          {form.values.identificationType === 'pin' && (
            <PinTextField
              name="pin"
              label={t('FORGOT_PASSWORD_PIN_CODE')}
              autoComplete={t('FORGOT_PASSWORD_PIN_CODE')}
              inputProps={{ minLength: 4, maxLength: 4 }}
              {...form.props('pin')}
            />
          )}
          {form.values.identificationType === 'ein' && (
            <PinTextField
              name="ein"
              label={t('FORGOT_PASSWORD_LAST_FOUR_EIN')}
              autoComplete={t('FORGOT_PASSWORD_LAST_FOUR_EIN')}
              type="text"
              inputProps={{ minLength: 4, maxLength: 4 }}
              {...form.props('ein')}
            />
          )}
          {form.values.identificationType === 'dob' && (
            <DatePicker
              style={{ width: '100%' }}
              label={t('DATE_OF_BIRTH')}
              name="dob"
              {...form.props('dob')}
            />
          )}
          {form.values.identificationType === 'itin' && (
            <PinTextField
              name="itin"
              label={t('FORGOT_PASSWORD_LAST_FOUR_ITIN')}
              autoComplete={t('FORGOT_PASSWORD_LAST_FOUR_ITIN')}
              inputProps={{ minLength: 4, maxLength: 4 }}
              {...form.props('itin')}
            />
          )}
        </form>
        <Grid container className={classes.cardActions}>
          <Grid item>
            <Button
              className={classes.submit}
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              onClick={form.handleSubmit}
            >
              {t('SUBMIT')}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
