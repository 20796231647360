import React, { FunctionComponent } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import TextField, { TextFieldProps } from '../text-field/TextField';
const EmailOrPhoneTextField: FunctionComponent<
  TextFieldProps | any
> = React.forwardRef((props: any, ref?: React.Ref<HTMLInputElement>) => {
  const { t } = useTranslation();
  return (
    <TextField
      data-testid={'email-phone-input'}
      name={'emailPhone'}
      margin={'normal'}
      ref={ref}
      label={props.label || t('EMAIL_OR_PHONE')}
      style={props.style || { width: '100%' }}
      variant={'outlined'}
      error={!!props.error}
      helperText={props.helperText || props.error}
      {...props}
    />
  );
});
export default EmailOrPhoneTextField;
