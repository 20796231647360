import { convertValidationRules } from '../../hooks/useFormState';
import { BaseValidationContext } from '../../hooks/useFormState.types';
import { useTranslation } from '../../hooks/useTranslation';
import {
  validateBirthdate,
  validateDriversLicense,
  validateEmailOrPhone,
  validateSSN,
} from '../../util/form-validation';

const { t } = useTranslation();

export type Model = {
  ssn: string;
  driversLicense: string;
  pin: string;
  dob: string;
  ein: string;
  itin: string;
  identificationType: 'driversLicense' | 'ssn' | 'pin' | 'dob' | 'ein' | 'itin';
  userIdType: 'email' | 'phone';
  emailPhone: string;
};

export const validateEIN = (ein = ''): string | null => {
  if (ein.length < 4) {
    return t('FORGOT_PASSWORD_LAST_FOUR_EIN_ERROR_MESSAGE');
  }
  return null;
};

export const validatePin = (pin = ''): string | null => {
  if (pin.length < 4) {
    return t('ERROR_PIN_CODE_REQUIRED');
  }
  return null;
};

export const validateITIN = (itin = ''): string | null => {
  if (itin.length < 4) {
    return t('FORGOT_PASSWORD_LAST_FOUR_ITIN_ERROR_MESSAGE');
  }
  return null;
};

export default convertValidationRules<Model>(
  (context: BaseValidationContext<Model>) => {
    const { userIdType, identificationType } = context.values;
    return {
      // this must match the names of the form fields
      emailPhone: validateEmailOrPhone,
      ssn: identificationType === 'ssn' ? validateSSN : null,
      driversLicense:
        identificationType === 'driversLicense' ? validateDriversLicense : null,
      pin: identificationType === 'pin' ? validatePin : null,
      ein: identificationType === 'ein' ? validateEIN : null,
      dob: identificationType === 'dob' ? validateBirthdate : null,
      itin: identificationType === 'itin' ? validateITIN : null,
    };
  },
);
