import gql from 'not-graphql-tag';

export const forgotPasswordMutation = gql`
  mutation validateAndUpdateTempPassword($payload: ForgotPasswordRequest) {
    validateAndUpdateTempPassword(payload: $payload) {
      forgotPasswordError
      signInToken
    }
  }
`;
